import changeLanguage from "@/components/changeLanguage.vue";
import { URL } from "@/api/activeEmail";
import { mapGetters } from "vuex";

export default {
  name: "sendEmail",
  components: {
    changeLanguage,
  },
  data() {
    return {
      coundown: 0,
      email: "",
      emailQuanLyTaiKhoan: "",
    };
  },
  created() {
    this.startCoundown();
    let email = this.getAuthUser.emailQuanLyTaiKhoan;
    this.emailQuanLyTaiKhoan = email;

    let index = 5;
    var arr = "";
    const leng = email.length - 2 * index;
    for (let index = 0; index < leng; index++) {
      arr += "*";
    }
    this.email =
      email.substring(0, index) + arr + email.substring(email.length - index);
  },
  methods: {
    startCoundown() {
      if (this.coundown > 0) {
        setTimeout(() => {
          this.coundown -= 1;
          this.startCoundown();
        }, 1000);
      }
    },
    async againSendEmail() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "main" });
        return;
      }
      this.coundown = 5;
      this.startCoundown();
      const data = {};
      data.emailQuanLyTaiKhoan = this.emailQuanLyTaiKhoan;
      const result = await this.$request({
        url: URL.SEND_AGAIN_EMAIL,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.code == 200) {
        this.$swal({
          text: res.messages,
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getAuthUser"]),
  },
};
