<div class="reset-password">
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-screen">
    <div
      class="
        col-span-1
        md:col-span-1
        lg:col-span-2
        p-10
        md:block
        bg-gray-100
        hidden
      "
    >
      <div class="flex justify-between items-center">
        <img src="@/assets/icons/logo.svg" alt="" class="w-14 h-14" />
      </div>
      <div class="flex items-center justify-center">
        <img src="@/assets/forgot-password.svg" alt="photo" class="p-10" />
      </div>
    </div>

    <div class="p-10 md:p-14">
      <!-- <div class="flex justify-end">
        <changeLanguage />
      </div> -->
      <div class="h-full flex items-center justify-center">
        <div>
          <h3 class="font-bold text-xl mb-2">Xác thực tài khoản của bạn</h3>
          <p class="enter-character text-base font-400 mb-5">
            Đường link kích hoạt tài khoản đã được gửi đến
            <span class="font-bold"> {{ email }} </span> <br />
            Vui lòng kiểm tra email để xác thực tài khoản của bạn
          </p>
          <div class="otp-coundown mt-3">
            <span v-if="coundown > 0">
              Email sẽ được gửi lại sau: <b>{{ coundown }}</b>
              giây
            </span>
            <span v-else>
              Nếu bạn chưa nhận được email:
              <span
                @click="againSendEmail"
                class="text-blue-400 hover:text-blue-700 cursor-pointer"
              >
                Gửi lại
              </span>
            </span>
          </div>

          <div class="resgiter mt-4">
            <router-link :to="{ name: 'login' }">
              <button
                class="
                  bg-blue-500
                  hover:bg-blue-600
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded
                "
                style="width: 100%"
              >
                Quay lại trang đăng nhập
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
